import React from "react"
import styled from "styled-components"
import ChainedList from "components/_blueprint/ChainedList"
import { Heading, Paragraph } from "components/_blueprint/Typography"
import { desktop, Gap } from "components/_blueprint/Layout"

const Box = styled.div({
  padding: "2rem 1rem",
  [desktop]: {
    marginRight: 250,
  },
})

export const HowItWorksRateCheckPromise = () => {
  return (
    <Box>
      <Heading size="h4" as="h2">
        How it works
      </Heading>
      <Gap x={2} />
      <ChainedList
        data={[
          {
            title: "We’ll notify you of lower rates",
            description: (
              <>
                <Paragraph>
                  Once you’ve had an offer accepted by a lender, we’ll check every day to see if
                  they have reduced rates for your deal.
                </Paragraph>
                <Paragraph>
                  You’ll hear from us via text message and email when a lower rate is found.
                </Paragraph>
              </>
            ),
          },
          {
            title: "Request new deal ",
            description: (
              <Paragraph>
                If you’d like to move onto the new deal with the lower rate, let us know quickly by
                selecting ‘Request this rate’. We’ll submit the request to your chosen lender.
                Occasionally, the lender might run a hard credit check.
              </Paragraph>
            ),
          },
          {
            title: "Move onto new rate & save ££",
            description: (
              <Paragraph>
                You’ll complete as usual. This change won’t cost you anything. You’ll just pay less
                for your mortgage.
              </Paragraph>
            ),
          },
        ]}
      />
      <Paragraph>
        If rates drop again, we’ll let you know. We’ll make every effort to move you onto your new
        rate, unless it will cause delays to completion.
      </Paragraph>
    </Box>
  )
}
