import React from "react"
import styled from "styled-components"
import { Paragraph } from "components/_blueprint/Typography"
import { Accordion, AccordionDataSection } from "pages/Application/Eligibility/Coach/Accordion"

const Container = styled.div({
  margin: "2rem 0",
})

export const RateCheckPromiseFaqs = () => (
  <Container>
    <Accordion data={FaqData} />
  </Container>
)

const FaqSection = styled.div({
  lineHeight: "1.5em",
})

const FaqP = styled(Paragraph)({
  fontSize: "16px",
})

const FaqData: AccordionDataSection[] = [
  {
    name: "Why have interest rates gone down?",
    id: "faq-1",
    trackingLabelPrefix: "Rate-Check-Promise-FAQs",
    page: (
      <FaqSection>
        <FaqP>
          Lenders will often change their rates based on a number of factors. Sometimes it’s to
          attract or retain customers in a competitive market. Other times it’s in response to
          Government policies or changes to the Bank of England base rate.
        </FaqP>
      </FaqSection>
    ),
  },
  {
    name: "Will it cost me money to change?",
    id: "faq-2",
    trackingLabelPrefix: "Rate-Check-Promise-FAQs",
    page: (
      <FaqSection>
        <FaqP>
          No. Our Rate Check Promise is part of our free service. When we monitor your lender for
          lower rates we make sure that we’re searching for a like-for-like deal, so the only thing
          that changes is the interest rate. If you’ve paid any product fees upfront, you may need
          to request a refund manually from the lender — but we’ll let you know if this is the case.
        </FaqP>
      </FaqSection>
    ),
  },
  {
    name: "Will it delay my completion date?",
    id: "faq-3",
    trackingLabelPrefix: "Rate-Check-Promise-FAQs",
    page: (
      <FaqSection>
        <FaqP>
          No, it shouldn’t. Our Rate Check Promise keeps you updated all the way until completion.
          Lenders have different restrictions on how long it takes to move rates, so if it’s too
          close to completion, we might not be able to move you in order to ensure there are no
          delays.
        </FaqP>
      </FaqSection>
    ),
  },
  {
    name: "Will my rate go up?",
    id: "faq-4",
    trackingLabelPrefix: "Rate-Check-Promise-FAQs",
    page: (
      <FaqSection>
        <FaqP>
          No. Once we’ve locked in a rate it won’t go up, unless your mortgage type is a variable
          rate. We’ll only ever notify you of a new rate when we think it could save you money.
        </FaqP>
      </FaqSection>
    ),
  },
  {
    name: "Will I be accepted for the new rate?",
    id: "faq-5",
    trackingLabelPrefix: "Rate-Check-Promise-FAQs",
    page: (
      <FaqSection>
        <FaqP>
          As the lender has already checked your affordability and eligibility, you’re highly likely
          to be accepted for the new rate. In our experience it is very rare that a lender won’t
          move a customer onto the lower rate, but it can happen, most notably if your circumstances
          have changed. Occasionally, a lender will run a hard credit check before moving you onto
          the new rate. If you have any concerns about not being accepted, please speak to your
          Mortgage Expert.
        </FaqP>
      </FaqSection>
    ),
  },
  {
    name: "Do you check other lenders?",
    id: "faq-6",
    trackingLabelPrefix: "Rate-Check-Promise-FAQs",
    page: (
      <FaqSection>
        <FaqP>
          Due to the complexity of the mortgage market and differences with how each lender manages
          their interest rates, we don’t run automatic checks for other lenders. However, you can
          contact your Mortgage Expert and ask them to check the whole market for a lower rate and
          they’ll conduct a manual check for you.
        </FaqP>
      </FaqSection>
    ),
  },
]
