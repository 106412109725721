import { LogGaEvent } from "lib/GoogleAnalytics"
import React, { useState } from "react"
import styled from "styled-components"

// @ts-ignore
import ArrowDown from "url:assets/images/icons/ArrowDown.svg"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import CoachRating, { CoachRatings } from "./TitleState"
import devices from "styles/devices"
import { SetGaDimensions } from "../../../../lib/GoogleAnalytics"

const AccordionButton = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s;
  padding: 15px 0;
  cursor: pointer;

  @media screen and ${devices.bp_lg} {
    &:hover {
      background-color: #f3f3f3;
    }
  }

  .arrow-icon {
    margin-left: 10px;
    transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
    ::after {
      content: url(${ArrowDown});
    }
  }
`

const ButtonName = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 18px;
    font-weight: 550;
    margin: 0;
  }
`

const Status = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-right: 10px;
  &.Poor {
    color: red;
  }
  &.Fair {
    color: orange;
  }
  &.Good {
    color: blue;
  }
  &.Great {
    color: blue;
  }
  &.Unknown {
    color: gray;
  }
`

export const AccordionPanel = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? "fit-content" : "0")};
  overflow: hidden;
  transition: 0.8s;
  border-bottom: 1px solid ${(props) => props.theme.shade80};
  :last-child {
    border-bottom: none;
  }
`

export type AccordionDataSection = {
  /**
   * The accordion's clickable title
   */
  name: string
  status?: "Poor" | "Fair" | "Good" | "Great" | "Unknown"
  id: string
  /**
   * The accordion's content
   */
  page: JSX.Element
  titleState?: CoachRatings
  statusComponent?: JSX.Element
  trackingLabelPrefix?: string
}

type Props = {
  data: AccordionDataSection[]
  fullTitleAndRating?: string
}

export const Accordion = ({ data, fullTitleAndRating }: Props) => {
  const [openState, setOpenState] = useState<boolean[]>([])

  SetGaDimensions({
    mortgage_coach_status: fullTitleAndRating,
  })

  const trackRating = (name, rating) => {
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: `Mortgage coach: ${name}`,
      field_value: rating,
    })

    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: "Mortgage Coach Summary",
      field_value: fullTitleAndRating,
    })
  }

  return (
    <>
      {data.map(
        (
          { name, status, id, page, statusComponent, trackingLabelPrefix, titleState = "" },
          idx
        ) => {
          return (
            <React.Fragment key={id + "-tab"}>
              <AccordionButton
                onClick={() => {
                  LogGaEvent({
                    action: openState[idx] ? EventAction.closeAccordion : EventAction.openAccordion,
                    event_category: EventCategory.functionalInteraction,
                    event_label: `${trackingLabelPrefix ? trackingLabelPrefix + " - " : ""}${name}`,
                  })
                  setOpenState(data.map((_, i) => idx === i && !openState[i]))
                }}
                isOpen={openState[idx]}
              >
                <ButtonName>
                  {status && <div className={"icon " + status} />}
                  <p className="name">{name}</p>
                </ButtonName>

                {statusComponent ? (
                  <Status>
                    {statusComponent}
                    <div className="arrow-icon" />
                  </Status>
                ) : (
                  <Status className={status} data-testid={`${name} - rating`}>
                    {titleState && (
                      <CoachRating
                        track={() => trackRating(name, titleState)}
                        titleState={titleState}
                      />
                    )}
                    <div className="arrow-icon" />
                  </Status>
                )}
              </AccordionButton>
              <AccordionPanel isOpen={openState[idx]}>{page}</AccordionPanel>
            </React.Fragment>
          )
        }
      )}
    </>
  )
}
